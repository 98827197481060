import request from '@/utils/service'

/**
 * 电塔仪表板数据
 * @author peng-xiao-shuai
 * @param {string} loginName
 * @param {string} loginPwd
 */
export function dashboard(params) {
  return request({
    url: '/tower/dashboard',
    method: 'get',
    params
  })
}

export const a = () => {}
